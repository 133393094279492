import React, { useState } from 'react';
import axios from 'axios';

import Button from '../common/Button/Button';
import contactUsSvg from '../../assets/contact-us.svg';
import successSvg from '../../assets/contact-us-success.svg';
import useAnalytics from '../../hooks/useAnalytics';
import ContactUsForm from './ContactUsForm/ContactUsForm';

import { getMessages } from '../../locales';

import parse from 'html-react-parser';

const ContactUs = () => {
  const formspreeUrl = `https://formspree.io/f/${process.env.FORMSPREE_FORM_ID}`;

  const { trackEvent } = useAnalytics();
  const [status, setStatus] = useState('UNSET');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const submitForm = (data) => {
    setSubmitDisabled(true);

    axios({
      method: 'post',
      url: formspreeUrl,
      headers: {
        Accept: 'application/json',
      },
      data,
    })
      .then(() => {
        setStatus('SUCCESS');
        trackEvent('Submit Contact Us inquiry', { Location: 'Contact Us page' });
      })
      .catch(() => {
        setStatus('ERROR');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const messages = getMessages();

  return (
    <section className="container contact-us">
      <div className="row align-items-start">
        <div className="col-md-12 col-lg-6 contact-us-image order-1 text-sm-center text-lg-left order-sm-1">
          {status === 'UNSET' && (
            <>
              <h1>{messages['contactUs.title']}</h1>
              <p>{messages['contactUs.subtitle']}</p>
            </>
          )}
          <img
            className="d-none d-lg-block"
            width="420"
            height="398"
            src={status === 'UNSET' ? contactUsSvg : successSvg}
            alt={messages['contactUs.altimage']}
          />
        </div>
        <div className="contact-us-content col-sm order-2 order-sm-2">
          {(status === 'UNSET' || status === 'ERROR') && (
            <div className="contact-form-wrapper">
              <ContactUsForm
                submitForm={submitForm}
                status={status}
                submitDisabled={submitDisabled}
              />
            </div>
          )}
          {status === 'SUCCESS' && (
            <div className="contact-form-success">
              <h1>{messages['contactUs.success.title']}</h1>
              <p>{messages['contactUs.success.desc']}</p>
            </div>
          )}
          <div className="contact-us-disclaimer-block">
            <p className="contact-us-disclaimer-title">{messages['contactUs.disclaimer']}</p>
            <div className="row no-gutter">
              <p className="col-sm-12 col-lg-6">{parse(messages['contactUs.disclaimerDetails'])}</p>
              <p className="col-sm-12 col-lg-6">
                {parse(messages['contactUs.disclaimerDetails2'])}
              </p>
              {status === 'SUCCESS' && (
                <div className="col-12">
                  <Button
                    buttonStyle="primary"
                    size="medium"
                    wide
                    title={messages['contactUs.success.button']}
                    linkTo="/"
                    className="submitButton padded"
                  />
                </div>
              )}
              <p className="col-12">{messages['footer.knowYourRights']}</p>
              <p className="col-12">{messages['footer.copyright']}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
